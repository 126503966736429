import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
    username: localStorage.getItem('username') ? localStorage.getItem('username') : '',
    phone: localStorage.getItem('phone') ? localStorage.getItem('phone') : '',
    nickname: localStorage.getItem('nickname') ? localStorage.getItem('nickname') : ''
  },
  mutations: {
    saveToken (state, user) {
      state.Authorization = user.Authorization
      localStorage.setItem('Authorization', user.Authorization)
    },
    saveUsername (state, user) {
      state.username = user.username
      localStorage.setItem('username', user.username)
    },
    savePhone (state, phone) {
      state.phone = phone
      localStorage.setItem('phone', phone)
    },
    saveNickName (state, name) {
      state.nickname = name
      localStorage.setItem('nickname', name)
    }
  },
  getters: {
    getUsername: state => state.username,
    getToken: state => state.Authorization,
    getPhone: state => state.phone,
    getNickName: state => state.nickname
  },
  actions: {
  },
  modules: {
  }
})
