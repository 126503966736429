import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import './utils/flexible.js'
import './assets/common.less'
import axios from 'axios'
import md5 from 'js-md5'
import './utils/util.js'

Vue.config.productionTip = false
Vue.use(Vant)

const BASEURL = '/apis'
axios.defaults.baseURL = BASEURL
axios.defaults.timeout = 5000
axios.interceptors.request.use(
  config => {
    if (localStorage.getItem('Authorization')) {
      config.headers.Authorization = localStorage.getItem('Authorization')
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

Vue.prototype.$axios = axios
Vue.prototype.$md5 = md5

Vue.directive('clickWithAuthLogin', {
  bind: function (el, binding, vnode) {
    el.onclick = function (event) {
      // 阻止事件冒泡
      event.stopPropagation()
      // 监听点击事件，判断是否登录
      if (!vnode.context.$store.getters.getToken) {
        // 未登录，跳转登录，附带当前页面的path,登录成功再跳转到本页面
        vnode.context.$router.replace({ path: '/login', query: { lastUrl: vnode.context.$route.fullPath } })
      } else {
        if (binding.expression) {
          // 已登录则，如果有后续执行的函数，则继续做组件自己的事件
          binding.value()
        }
      }
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
