<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import Vue from 'vue'

export default {
  name: 'App',
  created () {
    /* 登录失效 清除sessionStorage */
    Vue.prototype.afresh = (msg, type) => {
      this.$toast({
        message: msg,
        type: type
      })
      localStorage.removeItem('Authorization')
      localStorage.removeItem('username')
      this.$router.push({
        name: 'login'
      })
    }
  }
}
</script>
<style>
#app,html,body {
  /* font-family: 'Microsoft YaHei'; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFFFF;
  color: #323232;
}
</style>
